'use strict';

module.exports = {};

module.exports.show_callout = function show_callout(id, msg) {
	var html = "<div class='callout callout-info'>" + msg + '</div>';
	$('#' + id).html(html);
};

var blogger_summaries = {}; // hmm global scope :(

module.exports.update_blogger = function update_blogger(e) {
	e.preventDefault();

	var blogger_id = $(this).data('blogger_id');
	var status = $(this).data('status');
	var undo = $(this).data('undo');
	var id = 'blogger-summary-' + blogger_id;

	$.ajax({
		method: 'POST',
		url: '/admin/ajax/blogger-set-status',
		data: {
			blogger_id: blogger_id,
			status: status
		},
		success: function (data) {
			if (!data.success || data.error) {
				/* jshint devel:true */
				return alert('Error: ' + data.message);
			}
			if (undo) {
				$('#' + id).html(blogger_summaries[id]);
			} else {
				blogger_summaries[id] = $('#' + id).html();
				var nick = $('#' + id).data('nick');
				module.exports.show_callout(
					id,
					'<b>' +
					nick +
					':</b> ' +
					status +
					" <a class='btn btn-danger moderator-update-blogger-status pull-right' data-blogger_id='" +
					blogger_id +
					"' data-status='new' data-undo='true' href='#'>Undo</a>"
				);
			}

			$('.moderator-update-blogger-status')
				.off('click')
				.on('click', update_blogger);
		},
		error: function (error) {
			console.log(error);
			/* jshint devel:true */
			alert('an error has occured');
		}
	});
};

function setup() {
	$('.moderator-update-blogger-status').on(
		'click',
		module.exports.update_blogger
	);
};

if (window.jQuery) {
	// jQuery is loaded
	setup();
} else {
	// jQuery is not loaded
	console.log("jQuery hasn't been loaded yet.");
	// setup jquery
	$ = require('jquery');
	setup();
}